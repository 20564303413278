import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
// material-ui
import { useTheme } from '@mui/material/styles';
import { AppsOutlined } from '@mui/icons-material';
// third party
import Transitions from 'components/@extended/Transitions';
import MainCard from 'components/MainCard';

// project import
import DrawerHeaderStyled from './DrawerHeaderStyled';
import { Box, Stack, IconButton, Popper, Paper, ClickAwayListener, Typography } from '@mui/material';
// import Logo from 'components/logo';
import LogoImage from 'assets/images/Khora_admin_logo_white.png';
import LogoImage1 from 'assets/images/logo.png';
import KhoraImage from 'assets/images/khora_logo_final.svg';
import MarketImage from 'assets/images/logo_market.svg';
import SproutImage from 'assets/images/sprout_logo.svg';

// ==============================|| DRAWER HEADER ||============================== //

const DrawerHeader = ({ open }) => {
  const theme = useTheme();

  const anchorRef = useRef(null);
  const [logoOpen, setLogoOpen] = useState(false);
  const toggle = () => {
    setLogoOpen((prevOpen) => !prevOpen);
  };
  const close = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setLogoOpen(false);
  };

  return (
    <>
      <DrawerHeaderStyled theme={theme} open={open}>
        <Stack direction="row" alignItems="center" spacing={1.25}>
          <IconButton ref={anchorRef} onClick={toggle} sx={{ display: open ? '' : 'none' }}>
            <AppsOutlined sx={{ color: theme.palette.primary[100], fontSize: '2.5rem' }} />
          </IconButton>
          <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
            <img src={LogoImage} alt="logo" style={{ height: 20, display: open ? '' : 'none' }} />
            <img src={LogoImage1} alt="logo" style={{ height: 40, display: open ? 'none' : '' }} />
          </Box>
        </Stack>
        <Popper
          placement="bottom-start"
          open={logoOpen}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          popperOptions={{
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, 9]
                }
              }
            ]
          }}
          sx={{ zIndex: 2 }}
        >
          {({ TransitionProps }) => (
            <Transitions type="fade" in={logoOpen} {...TransitionProps}>
              {logoOpen && (
                <Paper
                  sx={{
                    border: `solid 1px ${theme.palette.grey[300]}`,
                    boxShadow: theme.customShadows.z1
                  }}
                >
                  <ClickAwayListener onClickAway={close}>
                    <MainCard elevation={0} border={false} sx={{ borderRadius: '4px' }}>
                      <Stack spacing={2} alignItems="flex-start">
                        <Typography color="secondary">Switch to</Typography>
                        <Box onClick={() => window.open(`${process.env['REACT_APP_SPROUT_URL']}`, '_blank')} sx={{ cursor: 'pointer' }}>
                          <img src={KhoraImage} alt="home logo" style={{ height: '16px', width: 'auto', cursor: 'pointer' }} />
                        </Box>
                        <Box
                          onClick={() => window.open(`${process.env['REACT_APP_MARKETPLACE_URL']}`, '_blank')}
                          sx={{ cursor: 'pointer' }}
                        >
                          <img src={MarketImage} alt="market logo" style={{ height: '16px', width: 'auto', cursor: 'pointer' }} />
                        </Box>
                        <Box
                          onClick={() => window.open(`${process.env['REACT_APP_SPROUT_URL']}/dashboard`, '_blank')}
                          sx={{ cursor: 'pointer' }}
                        >
                          <img src={SproutImage} alt="sprout logo" style={{ height: '28px', width: 'auto', cursor: 'pointer' }} />
                        </Box>
                      </Stack>
                    </MainCard>
                  </ClickAwayListener>
                </Paper>
              )}
            </Transitions>
          )}
        </Popper>
      </DrawerHeaderStyled>
    </>
  );
};

DrawerHeader.propTypes = {
  open: PropTypes.bool
};

export default DrawerHeader;
