// third-party
import { FormattedMessage } from 'react-intl';

// assets
import StoreIcon from '@mui/icons-material/Store';
// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const market_farmers_market = {
  id: 'market_farmers_market',
  title: <FormattedMessage id="Farmers Market" />,
  type: 'item',
  url: '/market/farmers_market',
  icon: { image: false, icon: StoreIcon }
};

const market_farmers_market_ = {
  id: 'market_farmers_market',
  title: <FormattedMessage id="Investor Management" />,
  type: 'collapse',
  icon: { image: false, icon: StoreIcon },
  children: [
    {
      id: 'market_farmers_market_',
      title: <FormattedMessage id="Investor Management" />,
      type: 'item',
      url: '/market/farmers_market'
    }
  ]
};

export { market_farmers_market, market_farmers_market_ };
