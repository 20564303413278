// third-party
import { FormattedMessage } from 'react-intl';

// assets
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const taxes = {
  id: 'taxes',
  title: <FormattedMessage id="Taxes" />,
  type: 'collapse',
  icon: { image: false, icon: PaidOutlinedIcon },
  children: [
    {
      id: 'rates',
      title: <FormattedMessage id="Rates" />,
      type: 'item',
      url: '/market/taxes/rates'
    },
    {
      id: 'collection',
      title: <FormattedMessage id="Collection" />,
      type: 'item',
      url: '/market/taxes/collection'
    },
    {
      id: 'payments',
      title: <FormattedMessage id="Payments" />,
      type: 'item',
      url: '/market/taxes/payments'
    },
    {
      id: 'certificates',
      title: <FormattedMessage id="Certificates" />,
      type: 'item',
      url: '/market/taxes/certificates'
    }
  ]
};

export default taxes;
