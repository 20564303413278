import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';

import jwtDecode from 'jwt-decode';
import { LOGIN, LOGOUT, REGISTER } from 'store/reducers/actions';

import Loader from 'components/Loader';
import axios from 'utils/axios';

const verifyToken = (jwtToken) => {
  if (!jwtToken) {
    return false;
  }
  const decoded = jwtDecode(jwtToken);
  return decoded.exp > Date.now() / 1000;
};

const setSession = (jwtToken) => {
  if (jwtToken) {
    localStorage.setItem('jwtTokenAdmin', jwtToken);
    axios.defaults.headers.common.Authorization = `Bearer ${jwtToken}`;
  } else {
    localStorage.removeItem('jwtTokenAdmin');
    delete axios.defaults.headers.common.Authorization;
  }
};

const SET_ERROR = 'SET_ERROR';

const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null,
  errMsg: null
};

const AuthReducer = (state, action) => {
  switch (action.type) {
    case REGISTER: {
      const { user } = action.payload;
      return {
        ...state,
        user
      };
    }
    case LOGIN: {
      const { user } = action.payload;
      return {
        ...state,
        isLoggedIn: true,
        isInitialized: true,
        user
      };
    }
    case LOGOUT: {
      return {
        ...state,
        isInitialized: true,
        isLoggedIn: false,
        user: null
      };
    }
    case SET_ERROR:
      return {
        ...state,
        errMsg: action.payload.errMsg
      };
    default: {
      return { ...state };
    }
  }
};
// ==============================|| JWT CONTEXT & PROVIDER ||============================== //

const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AuthReducer, initialState);

  useEffect(() => {
    const init = async () => {
      try {
        const jwtToken = window.localStorage.getItem('jwtTokenAdmin');
        if (jwtToken && verifyToken(jwtToken)) {
          setSession(jwtToken);
          const response = await axios.get('/auth');
          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true,
              user: response.data
            }
          });
        } else {
          dispatch({
            type: LOGOUT
          });
        }
      } catch (err) {
        dispatch({
          type: LOGOUT
        });
      }
    };
    init();
  }, []);

  const login = async (email, password) => {
    const response = await axios.post('/auth/login', { email, password });
    const { user, jwtToken } = response.data;
    setSession(jwtToken);
    dispatch({
      type: LOGIN,
      payload: {
        isLoggedIn: true,
        user
      }
    });
  };

  const register = async (email, password, passwordConfirm, name, phone_number) => {
    await axios.post('/auth/register', {
      email,
      user_id: email,
      password,
      passwordConfirm,
      name,
      phone_number
    });
  };

  const logout = () => {
    setSession(null);
    dispatch({ type: LOGOUT });
  };

  const resetPassword = async (email) => {
    await axios.post('/auth/forgot', { email });
  };

  const passwordReset = async (password, passwordConfirm, token) => {
    await axios.post('/auth/reset', { password, passwordConfirm, token });
  };

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return (
    <JWTContext.Provider
      value={{
        ...state,
        login,
        logout,
        register,
        passwordReset,
        resetPassword
      }}
    >
      {children}
    </JWTContext.Provider>
  );
};

JWTProvider.propTypes = {
  children: PropTypes.node
};

export default JWTContext;
