// ==============================|| OVERRIDES - TABLE CELL ||============================== //

export default function TableCell(theme) {
  return {
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontWeight: 400,
          fontSize: '0.875rem',
          lineHeight: '1.25rem',
          color: theme.palette.common.black,
          border: 'none'
        },
        sizeSmall: {
          padding: 4
        },
        head: {
          fontWeight: 500,
          fontSize: '0.875rem',
          lineHeight: '1.25rem',
          border: 'none'
        },
        footer: {
          fontWeight: 400,
          fontSize: '0.875rem',
          lineHeight: '1.25rem'
        }
      }
    }
  };
}
