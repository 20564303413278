// third-party
import { FormattedMessage } from 'react-intl';

// assets
import GradeOutlinedIcon from '@mui/icons-material/GradeOutlined';
// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const market_news = {
  id: 'market_news',
  title: <FormattedMessage id="News Management" />,
  type: 'collapse',
  icon: { image: false, icon: GradeOutlinedIcon },
  children: [
    {
      id: 'category',
      title: <FormattedMessage id="News Category" />,
      type: 'item',
      url: '/market/news/category'
    },
    {
      id: 'list',
      title: <FormattedMessage id="News List" />,
      type: 'item',
      url: '/market/news/list'
    }
  ]
};

export default market_news;
