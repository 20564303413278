// assets
import { RightOutlined } from '@ant-design/icons';

// ==============================|| OVERRIDES - ALERT TITLE ||============================== //

export default function AccordionSummary(theme) {
  return {
    MuiAccordionSummary: {
      defaultProps: {
        expandIcon: <RightOutlined style={{ fontSize: '14px' }} />
      },
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.common.white,
          border: `1px solid ${theme.palette.primary[300]}`,
          borderRadius: '10px',
          flexDirection: 'row-reverse',
          minHeight: 46,
          '&.Mui-disabled': {
            opacity: 1,
            backgroundColor: theme.palette.grey.lighter
          },
          '&.Mui-expanded': {
            borderRadius: '10px 10px 0px 0px'
          }
        },
        expandIconWrapper: {
          '&.Mui-expanded': {
            transform: 'rotate(90deg)'
          }
        },
        content: {
          padding: '14px 30px'
        }
      }
    }
  };
}
