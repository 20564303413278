// ==============================|| OVERRIDES - TAB ||============================== //

export default function Tab(theme) {
  return {
    MuiTab: {
      styleOverrides: {
        root: {
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '20px',
          maxWidth: '100%',
          padding: '30px 20px',
          color: theme.palette.secondary[900],
          '&:hover': {
            color: theme.palette.secondary.main
          },
          '&.Mui-disabled': {
            color: theme.palette.secondary[300]
          },
          '&.Mui-selected': {
            color: theme.palette.secondary[900]
          }
        }
      }
    }
  };
}
