// third-party
import { FormattedMessage } from 'react-intl';

// assets
import InvestorIcon from '@mui/icons-material/AssignmentIndOutlined';
// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const market_investor = {
  id: 'market_investor',
  title: <FormattedMessage id="Investor Management" />,
  type: 'item',
  url: '/market/investor',
  icon: { image: false, icon: InvestorIcon }
};

const market_investor_ = {
  id: 'market_investor',
  title: <FormattedMessage id="Investor Management" />,
  type: 'collapse',
  icon: { image: false, icon: InvestorIcon },
  children: [
    {
      id: 'market_investor_',
      title: <FormattedMessage id="Investor Management" />,
      type: 'item',
      url: '/market/investor'
    }
  ]
};

export { market_investor, market_investor_ };
