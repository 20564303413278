// third-party
import { FormattedMessage } from 'react-intl';

// assets
import VendorIcon from 'assets/icons/vendor/vendor.png';
// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const vendor = {
  id: 'vendor',
  title: <FormattedMessage id="Vendor" />,
  type: 'collapse',
  icon: { image: true, icon: VendorIcon },
  children: [
    {
      id: 'payment_report',
      title: <FormattedMessage id="Payment Report" />,
      type: 'item',
      url: '/market/vendor/payment_report',
      children: [
        {
          id: 'payment_report_detail',
          title: <FormattedMessage id="Detail Information" />,
          type: 'hidden',
          url: '/market/vendor/payment_report/detail'
        }
      ]
    },
    {
      id: 'payment_history',
      title: <FormattedMessage id="Payment History" />,
      type: 'item',
      url: '/market/vendor/payment_history'
    }
  ]
};

export default vendor;
