// third-party
import { FormattedMessage } from 'react-intl';

// assets
import UserIcon from '@mui/icons-material/PersonOutlineOutlined';

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const user = {
  id: 'user',
  title: <FormattedMessage id="User Management" />,
  type: 'item',
  url: '/users',
  icon: { image: false, icon: UserIcon },
  children: [
    {
      id: 'user_detail',
      title: <FormattedMessage id="User Detail Information" />,
      type: 'hidden',
      url: '/users/detail'
    }
  ]
};
const user_ = {
  id: 'user',
  title: <FormattedMessage id="User Management" />,
  type: 'collapse',
  icon: { image: false, icon: UserIcon },
  children: [
    {
      id: 'user_',
      title: <FormattedMessage id="User Management" />,
      type: 'item',
      url: '/users',
      children: [
        {
          id: 'user_detail',
          title: <FormattedMessage id="User Detail Information" />,
          type: 'hidden',
          url: '/users/detail'
        }
      ]
    }
  ]
};

export { user, user_ };
