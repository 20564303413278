// third-party
import { FormattedMessage } from 'react-intl';

// assets
// import { LineChartOutlined, IdcardOutlined, DatabaseOutlined } from '@ant-design/icons';
import DropDownIcon from '@mui/icons-material/FormatListBulletedOutlined';
// icons
const icons = {
  DropDownIcon
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const dropdown = {
  id: 'dropdown',
  title: <FormattedMessage id="Dropdown Management" />,
  type: 'collapse',
  icon: { image: false, icon: icons.DropDownIcon },
  children: [
    {
      id: 'containerdropdown',
      title: <FormattedMessage id="Container" />,
      type: 'item',
      url: '/dropdown/container'
    },
    {
      id: 'costcategorydropdown',
      title: <FormattedMessage id="Cost Category" />,
      type: 'item',
      url: '/dropdown/cost_category'
    },
    {
      id: 'enhancerdropdown',
      title: <FormattedMessage id="Enhancer" />,
      type: 'item',
      url: '/dropdown/enhancer'
    },
    {
      id: 'enzymedropdown',
      title: <FormattedMessage id="Enzyme" />,
      type: 'item',
      url: '/dropdown/enzyme'
    },
    {
      id: 'fertilizerdropdown',
      title: <FormattedMessage id="Fertilizer" />,
      type: 'item',
      url: '/dropdown/fertilizer'
    },
    {
      id: 'growingtaskdropdown',
      title: <FormattedMessage id="Growing Step & Task" />,
      type: 'item',
      url: '/dropdown/growing'
    },
    {
      id: 'nutrientdropdown',
      title: <FormattedMessage id="Nutrient" />,
      type: 'item',
      url: '/dropdown/nutrient'
    },
    {
      id: 'growinggroupdropdown',
      title: <FormattedMessage id="Plant Group" />,
      type: 'item',
      url: '/dropdown/growing_group'
    },
    {
      id: 'plantvarietydropdown',
      title: <FormattedMessage id="Plant Variety & Common Type" />,
      type: 'item',
      url: '/dropdown/plant'
    },
    {
      id: 'predatorinsectdropdown',
      title: <FormattedMessage id="Predator Insect" />,
      type: 'item',
      url: '/dropdown/predatorinsect'
    },
    {
      id: 'soildropdown',
      title: <FormattedMessage id="Soil Medium" />,
      type: 'item',
      url: '/dropdown/soil_medium'
    },
    {
      id: 'soilgroupdropdown',
      title: <FormattedMessage id="Soil Seeding Temperature Group" />,
      type: 'item',
      url: '/dropdown/seeding_group'
    },
    {
      id: 'vendordropdown',
      title: <FormattedMessage id="Vendor" />,
      type: 'item',
      url: '/dropdown/vendor_type'
    }
    // {
    //   id: 'wholesaleretaildropdown',
    //   title: <FormattedMessage id="Wholesale & Retail Type" />,
    //   type: 'item',
    //   url: '/dropdown/wholesale_retail'
    // }
  ]
};

export default dropdown;
