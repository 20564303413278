// third-party
import { FormattedMessage } from 'react-intl';

// assets
import VendorIcon from 'assets/icons/vendor/vendor.png';

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const vendor = {
  id: 'vendor',
  title: <FormattedMessage id="Vendor Management" />,
  type: 'item',
  url: '/vendor',
  icon: { image: true, icon: VendorIcon },
  children: [
    {
      id: 'vender_create',
      title: <FormattedMessage id="Add Vendor" />,
      type: 'hidden',
      url: '/vendor/create'
    },
    {
      id: 'vender_update',
      title: <FormattedMessage id="Edit Vendor" />,
      type: 'hidden',
      url: '/vendor/update'
    }
  ]
};
const vendor_ = {
  id: 'vendor',
  title: <FormattedMessage id="Vendor Management" />,
  type: 'collapse',
  icon: { image: true, icon: VendorIcon },
  children: [
    {
      id: 'vendor_',
      title: <FormattedMessage id="Vendor Management" />,
      type: 'item',
      url: '/vendor',
      children: [
        {
          id: 'vender_create',
          title: <FormattedMessage id="Add Vendor" />,
          type: 'hidden',
          url: '/vendor/create'
        },
        {
          id: 'vender_update',
          title: <FormattedMessage id="Edit Vendor" />,
          type: 'hidden',
          url: '/vendor/update'
        }
      ]
    }
  ]
};
export { vendor, vendor_ };
