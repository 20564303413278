// ==============================|| OVERRIDES - ALERT TITLE ||============================== //

export default function AccordionDetails(theme) {
  return {
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: '40px 30px',
          backgroundColor: theme.palette.common.white,
          borderLeft: `solid 1px ${theme.palette.primary[300]}`,
          borderRight: `solid 1px ${theme.palette.primary[300]}`,
          borderBottom: `solid 1px ${theme.palette.primary[300]}`,
          borderRadius: '0px 0px 10px 10px'
        }
      }
    }
  };
}
