// ==============================|| OVERRIDES - LIST ITEM ICON ||============================== //

export default function FormControlLabel() {
  return {
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          margin: 0
        },
        label: {
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '20px'
        }
      }
    }
  };
}
