// material-ui
// ==============================|| OVERRIDES - TOOLTIP ||============================== //

export default function Tooltip(theme) {
  return {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          color: theme.palette.common.black,
          background: theme.palette.common.white,
          border: `solid 1px #ccc`,
          padding: '16px'
        }
      }
    }
  };
}
