// ==============================|| OVERRIDES - LIST ITEM ICON ||============================== //

export default function MenuItem() {
  return {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFFFFF',
          paddingTop: '12px',
          paddingBottom: '12px'
        }
      }
    }
  };
}
