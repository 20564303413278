// third-party
import { FormattedMessage } from 'react-intl';

// assets
import ProductIcon from '@mui/icons-material/Inventory2Outlined';

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const market_product = {
  id: 'market_product',
  title: <FormattedMessage id="Product Management" />,
  type: 'collapse',
  icon: { image: false, icon: ProductIcon },
  children: [
    {
      id: 'product_list',
      title: <FormattedMessage id="Product List" />,
      type: 'item',
      url: '/market/products/list'
    },
    {
      id: 'top10_product',
      title: <FormattedMessage id="Top 10 Products" />,
      type: 'item',
      url: '/market/products/top10'
    }
  ]
};

export default market_product;
