import { useRef, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Box,
  ButtonBase,
  ClickAwayListener,
  Grid,
  Paper,
  Popper,
  Stack,
  Typography,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider
} from '@mui/material';

// project import
import MainCard from 'components/MainCard';
import Transitions from 'components/@extended/Transitions';
import AvatarIcon from '@mui/icons-material/AccountCircleOutlined';
import { LogoutOutlined, UserOutlined } from '@ant-design/icons';

import useAuth from 'hooks/useAuth';

// ==============================|| HEADER CONTENT - PROFILE ||============================== //

const Profile = () => {
  const theme = useTheme();
  const { logout, user } = useAuth();
  const handleLogout = async () => {
    try {
      await logout();
    } catch (err) {
      console.error(err);
    }
  };

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <ButtonBase
        sx={{
          py: 0.25,
          px: 2,
          bgcolor: open ? 'primary.900' : 'transparent',
          borderRadius: 1,
          '&:hover': { bgcolor: 'primary.900' },
          '&:focus-visible': {
            outlineOffset: 2
          }
        }}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? 'profile-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Stack direction="row" spacing={2} alignItems="center" sx={{ p: 0.5 }}>
          <AvatarIcon sx={{ fill: theme.palette.common.white }} />
          <Typography sx={{ color: theme.palette.common.white }} variant="subtitle1">
            {user?.name}
          </Typography>
        </Stack>
      </ButtonBase>
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 9]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="fade" in={open} {...TransitionProps}>
            {open && (
              <Paper
                sx={{
                  boxShadow: theme.customShadows.z1,
                  width: 280,
                  minWidth: 280,
                  maxWidth: 280,
                  [theme.breakpoints.down('md')]: {
                    maxWidth: 280
                  }
                }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <MainCard elevation={0} border={false} content={false} sx={{ borderRadius: '4px' }}>
                    <Grid container justifyContent="space-between" alignItems="center">
                      <Grid item xs={12} px={2.25} py={1.25}>
                        <Stack direction="row" spacing={1.25} alignItems="center">
                          <AvatarIcon sx={{}} />
                          <Stack>
                            <Typography variant="h6">{user?.name}</Typography>
                            <Typography variant="body2" color="textSecondary">
                              {user?.email}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={12}>
                        <ListItemButton sx={{ px: 3 }}>
                          <ListItemIcon>
                            <UserOutlined />
                          </ListItemIcon>
                          <ListItemText primary="Account Settings" />
                        </ListItemButton>
                      </Grid>
                      <Grid item xs={12}>
                        <ListItemButton onClick={handleLogout} sx={{ px: 3 }}>
                          <ListItemIcon>
                            <LogoutOutlined />
                          </ListItemIcon>
                          <ListItemText primary="Logout" />
                        </ListItemButton>
                      </Grid>
                    </Grid>
                  </MainCard>
                </ClickAwayListener>
              </Paper>
            )}
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};

export default Profile;
