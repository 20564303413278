// third-party
import { FormattedMessage } from 'react-intl';

// assets
// import { LineChartOutlined, IdcardOutlined, DatabaseOutlined } from '@ant-design/icons';
import GrowingMethodIcon from '@mui/icons-material/ViewAgendaOutlined';
// icons
const icons = {
  GrowingMethodIcon
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const growingplan_full = {
  id: 'growingplan-full',
  title: <FormattedMessage id="Create Growing Plan" />,
  type: 'collapse',
  icon: { image: false, icon: icons.GrowingMethodIcon },
  children: [
    {
      id: 'plantgrowingplan-full',
      title: <FormattedMessage id="Select Plant Variety" />,
      type: 'item',
      url: '/create_growing_plan/select'
    },
    {
      id: 'containergrowingplan-full',
      title: <FormattedMessage id="Create Transplant Steps" />,
      type: 'item',
      url: '/create_growing_plan/transplant_steps'
    },
    {
      id: 'taskgrowingplan-full',
      title: <FormattedMessage id="Create Growing Steps" />,
      type: 'item',
      url: '/create_growing_plan/growing_task'
    },
    {
      id: 'lightgrowingplan-full',
      title: <FormattedMessage id="Create Lighting Plan" />,
      type: 'item',
      url: '/create_growing_plan/light'
    },
    {
      id: 'environmentgrowingplan-full',
      title: <FormattedMessage id="Create Environment Plan" />,
      type: 'item',
      url: '/create_growing_plan/environment'
    },
    {
      id: 'irrigationgrowingplan-full',
      title: <FormattedMessage id="Create Irrigation Plan" />,
      type: 'item',
      url: '/create_growing_plan/irrigation_plan'
    }
  ]
};

export default growingplan_full;
