import { lazy } from 'react';

// project import
import MainLayout from 'layout/MainLayout';
// import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

import { KhoraSetupProvider } from 'contexts/KhoraSetupContext';

const SelectPlantVarietyPage = Loadable(lazy(() => import('pages/growingplan/create/plantvariety')));
const CreateTransplantStepPage = Loadable(lazy(() => import('pages/growingplan/create/transplantsteps')));
const CreateGrowingStepPage = Loadable(lazy(() => import('pages/growingplan/create/growingstep')));
const CreateLightingPlanPage = Loadable(lazy(() => import('pages/growingplan/create/lighting')));
const CreateEnvironmentPlanPage = Loadable(lazy(() => import('pages/growingplan/create/environment')));
const CreateIrrigationPlanPage = Loadable(lazy(() => import('pages/growingplan/create/irrigation')));

const SeedPage = Loadable(lazy(() => import('pages/resource/seed')));
const CreateSeedPage = Loadable(lazy(() => import('pages/resource/seed/create')));
const UpdateSeedPage = Loadable(lazy(() => import('pages/resource/seed/update')));
const MushroomPage = Loadable(lazy(() => import('pages/resource/mushroom')));
const CreateMushroomPage = Loadable(lazy(() => import('pages/resource/mushroom/create')));
const UpdateMushroomPage = Loadable(lazy(() => import('pages/resource/mushroom/update')));
const MicrogreenPage = Loadable(lazy(() => import('pages/resource/microgreen')));
const CreateMicrogreenPage = Loadable(lazy(() => import('pages/resource/microgreen/create')));
const UpdateMicrogreenPage = Loadable(lazy(() => import('pages/resource/microgreen/update')));
const CuttingPage = Loadable(lazy(() => import('pages/resource/cutting')));
const CreateCuttingPage = Loadable(lazy(() => import('pages/resource/cutting/create')));
const UpdateCuttingPage = Loadable(lazy(() => import('pages/resource/cutting/update')));
const IrrigationPage = Loadable(lazy(() => import('pages/resource/irrigation')));
const CreateIrrigationPage = Loadable(lazy(() => import('pages/resource/irrigation/create')));
const UpdateIrrigationPage = Loadable(lazy(() => import('pages/resource/irrigation/update')));
const LightingPage = Loadable(lazy(() => import('pages/resource/light')));
const CreateLightingPage = Loadable(lazy(() => import('pages/resource/light/create')));
const UpdateLightingPlanPage = Loadable(lazy(() => import('pages/resource/light/update')));
const CreateNewLightingPage = Loadable(lazy(() => import('pages/resource/new_light/create')));
const UpdateNewLightingPage = Loadable(lazy(() => import('pages/resource/new_light/update')));
const EnvironmentPage = Loadable(lazy(() => import('pages/resource/environment')));
const CreateEnvironmentPage = Loadable(lazy(() => import('pages/resource/environment/create')));
const UpdateEnvironmentPlanPage = Loadable(lazy(() => import('pages/resource/environment/update')));

const VendorPage = Loadable(lazy(() => import('pages/vendor')));
const CreateVendorPage = Loadable(lazy(() => import('pages/vendor/create')));
const UpdateVendorPage = Loadable(lazy(() => import('pages/vendor/update')));

const GrowingPlanPage = Loadable(lazy(() => import('pages/growingplan')));
const UpdateGrowingPlan = Loadable(lazy(() => import('pages/growingplan/update')));

const UserManagementPage = Loadable(lazy(() => import('pages/user')));
const UserDetailPage = Loadable(lazy(() => import('pages/user/detail')));

const PlantDropdownPage = Loadable(lazy(() => import('pages/dropdown/plant')));
const GrowingDropdownPage = Loadable(lazy(() => import('pages/dropdown/growing')));
const SoilMediumDropdownPage = Loadable(lazy(() => import('pages/dropdown/soilmedium')));
const ContainerDropdownPage = Loadable(lazy(() => import('pages/dropdown/container')));
const CostCategoryDropdownPage = Loadable(lazy(() => import('pages/dropdown/cost')));
const EnhancerDropdownPage = Loadable(lazy(() => import('pages/dropdown/enhancer')));
const EnzymeDropdownPage = Loadable(lazy(() => import('pages/dropdown/enzyme')));
const FertilizerDropdownPage = Loadable(lazy(() => import('pages/dropdown/fertilizer')));
const NutrientDropdownPage = Loadable(lazy(() => import('pages/dropdown/nutrient')));
const PredatorInsectDropdownPage = Loadable(lazy(() => import('pages/dropdown/predatorinsect')));
const VendorDropdownPage = Loadable(lazy(() => import('pages/dropdown/vendor')));
const PlantGroupDropdownPage = Loadable(lazy(() => import('pages/dropdown/plantgroup')));
const SeedingGroupDropdownPage = Loadable(lazy(() => import('pages/dropdown/soilgroup')));

const AnnouncementPage = Loadable(lazy(() => import('pages/announcement')));

const MembershipPage = Loadable(lazy(() => import('pages/membership')));

const ProductPage = Loadable(lazy(() => import('pages/stripe/product')));
const CreateProductPage = Loadable(lazy(() => import('pages/stripe/product/create')));
const UpdateProductPage = Loadable(lazy(() => import('pages/stripe/product/update')));
const TaxRatePage = Loadable(lazy(() => import('pages/stripe/taxrate')));
const CreateTaxRatePage = Loadable(lazy(() => import('pages/stripe/taxrate/create')));
const UpdateTaxRatePage = Loadable(lazy(() => import('pages/stripe/taxrate/update')));

//// Marketplace pages ////
const MarketUserManagementPage = Loadable(lazy(() => import('pages/market/user')));
const MarketUserDetailPage = Loadable(lazy(() => import('pages/market/user/detail')));
const MarketProductManagementPage = Loadable(lazy(() => import('pages/market/product/list')));
const MarketTop10ProudctPage = Loadable(lazy(() => import('pages/market/product/top10')));
const MarketTaxRatePage = Loadable(lazy(() => import('pages/market/taxes/rates')));
const MarketTaxCollectionPage = Loadable(lazy(() => import('pages/market/taxes/collection')));
const MarketTaxPaymentHistoryPage = Loadable(lazy(() => import('pages/market/taxes/payments')));
const MarketTaxCertificatePage = Loadable(lazy(() => import('pages/market/taxes/certificates')));
const MarketVendorPaymentReportPage = Loadable(lazy(() => import('pages/market/vendor/payment_report')));
const MarketVendorPaymentReportDetailPage = Loadable(lazy(() => import('pages/market/vendor/payment_report/detail')));
const MarketVendorPaymentHistoryPage = Loadable(lazy(() => import('pages/market/vendor/payment_history')));
const MarketReviewManagementPage = Loadable(lazy(() => import('pages/market/review')));
const MarketNewsCategoryPage = Loadable(lazy(() => import('pages/market/news/category')));
const MarketNewsListPage = Loadable(lazy(() => import('pages/market/news/list')));
const MarketInvestorPage = Loadable(lazy(() => import('pages/market/investor')));
const MarketFarmersMarketListPage = Loadable(lazy(() => import('pages/market/farmers_market/list')));
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: (
    <AuthGuard>
      <KhoraSetupProvider>
        <MainLayout />
      </KhoraSetupProvider>
    </AuthGuard>
  ),
  children: [
    {
      path: 'list',
      children: [
        {
          path: 'seed',
          element: <SeedPage />
        },
        {
          path: 'seed/create',
          element: <CreateSeedPage />
        },
        {
          path: 'seed/update/:id',
          element: <UpdateSeedPage />
        },
        {
          path: 'mushroom',
          element: <MushroomPage />
        },
        {
          path: 'mushroom/create',
          element: <CreateMushroomPage />
        },
        {
          path: 'mushroom/update/:id',
          element: <UpdateMushroomPage />
        },
        {
          path: 'microgreen',
          element: <MicrogreenPage />
        },
        {
          path: 'microgreen/create',
          element: <CreateMicrogreenPage />
        },
        {
          path: 'microgreen/update/:id',
          element: <UpdateMicrogreenPage />
        },
        {
          path: 'cutting',
          element: <CuttingPage />
        },
        {
          path: 'cutting/create',
          element: <CreateCuttingPage />
        },
        {
          path: 'cutting/update/:id',
          element: <UpdateCuttingPage />
        },
        {
          path: 'irrigation',
          element: <IrrigationPage />
        },
        {
          path: 'irrigation/create',
          element: <CreateIrrigationPage />
        },
        {
          path: 'irrigation/update/:id',
          element: <UpdateIrrigationPage />
        },
        {
          path: 'light',
          element: <LightingPage />
        },
        {
          path: 'light/create',
          element: <CreateLightingPage />
        },
        {
          path: 'light/update/:id',
          element: <UpdateLightingPlanPage />
        },
        {
          path: 'light/create_new',
          element: <CreateNewLightingPage />
        },
        {
          path: 'light/update_new/:id',
          element: <UpdateNewLightingPage />
        },
        {
          path: 'environment',
          element: <EnvironmentPage />
        },
        {
          path: 'environment/create',
          element: <CreateEnvironmentPage />
        },
        {
          path: 'environment/update/:id',
          element: <UpdateEnvironmentPlanPage />
        }
      ]
    },
    {
      path: 'create_growing_plan',
      children: [
        {
          path: 'select',
          element: <SelectPlantVarietyPage />
        },
        {
          path: 'transplant_steps',
          element: <CreateTransplantStepPage />
        },
        {
          path: 'growing_task',
          element: <CreateGrowingStepPage />
        },
        {
          path: 'light',
          element: <CreateLightingPlanPage />
        },
        {
          path: 'environment',
          element: <CreateEnvironmentPlanPage />
        },
        {
          path: 'irrigation_plan',
          element: <CreateIrrigationPlanPage />
        }
      ]
    },
    {
      path: 'growing_plan',
      children: [
        {
          path: '',
          element: <GrowingPlanPage />
        },
        {
          path: 'Update',
          element: <UpdateGrowingPlan />
        }
      ]
    },
    {
      path: 'vendor',
      children: [
        {
          path: '',
          element: <VendorPage />
        },
        {
          path: 'create',
          element: <CreateVendorPage />
        },
        {
          path: 'update/:id',
          element: <UpdateVendorPage />
        }
      ]
    },
    {
      path: 'users',
      children: [
        {
          path: '',
          element: <UserManagementPage />
        },
        {
          path: 'detail/:id',
          element: <UserDetailPage />
        }
      ]
    },
    {
      path: 'dropdown',
      children: [
        {
          path: 'plant',
          element: <PlantDropdownPage />
        },
        {
          path: 'growing',
          element: <GrowingDropdownPage />
        },
        {
          path: 'soil_medium',
          element: <SoilMediumDropdownPage />
        },
        {
          path: 'container',
          element: <ContainerDropdownPage />
        },
        {
          path: 'cost_category',
          element: <CostCategoryDropdownPage />
        },
        {
          path: 'enhancer',
          element: <EnhancerDropdownPage />
        },
        {
          path: 'enzyme',
          element: <EnzymeDropdownPage />
        },
        {
          path: 'fertilizer',
          element: <FertilizerDropdownPage />
        },
        {
          path: 'nutrient',
          element: <NutrientDropdownPage />
        },
        {
          path: 'predatorinsect',
          element: <PredatorInsectDropdownPage />
        },
        {
          path: 'vendor_type',
          element: <VendorDropdownPage />
        },
        {
          path: 'growing_group',
          element: <PlantGroupDropdownPage />
        },
        {
          path: 'seeding_group',
          element: <SeedingGroupDropdownPage />
        }
      ]
    },
    {
      path: 'announcement',
      element: <AnnouncementPage />
    },
    {
      path: 'membership',
      element: <MembershipPage />
    },
    {
      path: 'stripe',
      children: [
        {
          path: 'product',
          element: <ProductPage />
        },
        {
          path: 'product/create',
          element: <CreateProductPage />
        },
        {
          path: 'product/update/:id',
          element: <UpdateProductPage />
        },
        {
          path: 'tax_rate',
          element: <TaxRatePage />
        },
        {
          path: 'tax_rate/create',
          element: <CreateTaxRatePage />
        },
        {
          path: 'tax_rate/update',
          element: <UpdateTaxRatePage />
        }
      ]
    },
    {
      path: 'market',
      children: [
        {
          path: 'users',
          children: [
            {
              path: '',
              element: <MarketUserManagementPage />
            },
            {
              path: 'detail/:id',
              element: <MarketUserDetailPage />
            }
          ]
        },
        {
          path: 'products',
          children: [
            {
              path: 'list',
              element: <MarketProductManagementPage />
            },
            {
              path: 'top10',
              element: <MarketTop10ProudctPage />
            }
          ]
        },
        {
          path: 'taxes',
          children: [
            {
              path: 'rates',
              element: <MarketTaxRatePage />
            },
            {
              path: 'collection',
              element: <MarketTaxCollectionPage />
            },
            {
              path: 'payments',
              element: <MarketTaxPaymentHistoryPage />
            },
            {
              path: 'certificates',
              element: <MarketTaxCertificatePage />
            }
          ]
        },
        {
          path: 'vendor',
          children: [
            {
              path: 'payment_report',
              children: [
                {
                  path: '',
                  element: <MarketVendorPaymentReportPage />
                },
                {
                  path: 'detail/:id',
                  element: <MarketVendorPaymentReportDetailPage />
                }
              ]
            },
            {
              path: 'payment_history',
              element: <MarketVendorPaymentHistoryPage />
            }
          ]
        },
        {
          path: 'review',
          children: [
            {
              path: '',
              element: <MarketReviewManagementPage />
            }
          ]
        },
        {
          path: 'investor',
          children: [
            {
              path: '',
              element: <MarketInvestorPage />
            }
          ]
        },
        {
          path: 'news',
          children: [
            {
              path: 'category',
              element: <MarketNewsCategoryPage />
            },
            {
              path: 'list',
              element: <MarketNewsListPage />
            }
          ]
        },
        {
          path: 'farmers_market',
          children: [
            {
              path: '',
              element: <MarketFarmersMarketListPage />
            }
          ]
        }
      ]
    }
  ]
};

export default MainRoutes;
