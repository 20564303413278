// third-party
import { FormattedMessage } from 'react-intl';

// assets
import GrowingPlanIcon from 'assets/icons/growing_plan_management/plan.png';
// icons
// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const growingplan = {
  id: 'growingplan',
  title: <FormattedMessage id="Growing Plan Management" />,
  type: 'item',
  url: '/growing_plan',
  icon: { image: true, icon: GrowingPlanIcon },
  children: [
    {
      id: 'growingplan_update',
      title: <FormattedMessage id="Edit Growing Plan" />,
      type: 'hidden',
      url: '/growing_plan/update'
    },
    {
      id: 'growingplan_view',
      title: <FormattedMessage id="View Growing Plan" />,
      type: 'hidden',
      url: '/growing_plan/view'
    }
  ]
};
const growingplan_ = {
  id: 'growingplan',
  title: <FormattedMessage id="Growing Plan Management" />,
  type: 'collapse',
  icon: { image: true, icon: GrowingPlanIcon },
  children: [
    {
      id: 'growingplan_',
      title: <FormattedMessage id="Growing Plan Management" />,
      type: 'item',
      url: '/growing_plan',
      children: [
        {
          id: 'growingplan_update',
          title: <FormattedMessage id="Edit Growing Plan" />,
          type: 'hidden',
          url: '/growing_plan/update'
        },
        {
          id: 'growingplan_view',
          title: <FormattedMessage id="View Growing Plan" />,
          type: 'hidden',
          url: '/growing_plan/view'
        }
      ]
    }
  ]
};

export { growingplan, growingplan_ };
