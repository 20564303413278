import PropTypes from 'prop-types';
import * as React from 'react';
import { useState, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';

// material-ui
import AppBar from '@mui/material/AppBar';
import { useTheme } from '@mui/material/styles';
import {
  useMediaQuery,
  Box,
  Container,
  Drawer,
  List,
  ListItemButton,
  ListItemText,
  Stack,
  Toolbar,
  useScrollTrigger,
  Button,
  IconButton,
  Popper,
  Paper,
  ClickAwayListener,
  Typography
} from '@mui/material';
// assets
// import { MenuOutlined, LineOutlined } from '@ant-design/icons';
import { MenuOutlined } from '@ant-design/icons';
import { AppsOutlined } from '@mui/icons-material';
// third party
import Transitions from 'components/@extended/Transitions';
import MainCard from 'components/MainCard';

import LogoImage from 'assets/images/Khora_admin_final-green.png';
import LogoImage1 from 'assets/images/logo.png';
import KhoraImage from 'assets/images/khora_logo_final.svg';
import MarketImage from 'assets/images/logo_market.svg';
import SproutImage from 'assets/images/sprout_logo.svg';

import useAuth from 'hooks/useAuth';
// ==============================|| COMPONENTS - APP BAR ||============================== //

// elevation scroll
function ElevationScroll({ layout, children, window }) {
  const theme = useTheme();
  // const theme = useTheme();

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 10,
    target: window ? window() : undefined
  });

  const backColorScroll = theme.palette.mode === 'dark' ? theme.palette.grey[50] : theme.palette.grey[800];
  const backColor = layout !== 'landing' ? backColorScroll : 'transparent';

  return React.cloneElement(children, {
    style: {
      backgroundColor: trigger ? backColorScroll : backColor
    }
  });
}

const Header = ({ layout = 'landing', ...others }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

  const { isLoggedIn, logout } = useAuth();
  const [drawerToggle, setDrawerToggle] = useState(false);

  /** Method called on multiple components with different event types */
  const drawerToggler = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerToggle(open);
  };

  const anchorRef = useRef(null);
  const [logoOpen, setLogoOpen] = useState(false);
  const toggle = () => {
    setLogoOpen((prevOpen) => !prevOpen);
  };
  const close = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setLogoOpen(false);
  };

  const _anchorRef = useRef(null);
  const [_logoOpen, setLogoOpen_] = useState(false);
  const _toggle = () => {
    setLogoOpen_((prevOpen) => !prevOpen);
  };
  const _close = (event) => {
    if (_anchorRef.current && _anchorRef.current.contains(event.target)) {
      return;
    }
    setLogoOpen_(false);
  };

  return (
    <>
      <ElevationScroll layout={layout} {...others}>
        <AppBar sx={{ bgcolor: 'transparent', color: theme.palette.text.primary, boxShadow: 'none' }}>
          <Container disableGutters={matchDownMd}>
            <Toolbar sx={{ px: { xs: 1.5, md: 0, lg: 0 }, py: 2, justifyContent: 'space-between' }}>
              <Box sx={{ display: { xs: 'none', md: 'block' }, alignItems: 'center', cursor: 'pointer' }}>
                <Stack direction="row" alignItems="center" spacing={1.25}>
                  <IconButton ref={anchorRef} onClick={toggle}>
                    <AppsOutlined sx={{ color: theme.palette.darkgreen[500], fontSize: '2.5rem' }} />
                  </IconButton>
                  <img src={LogoImage} alt="logo" style={{ height: 24, display: open ? '' : 'none' }} />
                </Stack>
              </Box>
              <Stack direction="row" spacing={2} sx={{ display: { xs: 'none', md: 'block' } }}>
                <Button variant="contained" onClick={() => navigate('/login')}>
                  {!isLoggedIn ? 'Login' : 'Home'}
                </Button>
                {isLoggedIn && (
                  <>
                    <Button variant="contained" onClick={logout}>
                      Logout
                    </Button>
                  </>
                )}
              </Stack>
              <Box
                sx={{
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  display: { xs: 'flex', md: 'none' }
                }}
              >
                <Box sx={{ display: 'inline-block', alignItems: 'center', cursor: 'pointer' }}>
                  <Stack direction="row" alignItems="center" spacing={1.25}>
                    <IconButton ref={_anchorRef} onClick={_toggle}>
                      <AppsOutlined sx={{ color: theme.palette.darkgreen[500], fontSize: '2.5rem' }} />
                    </IconButton>
                    <img src={LogoImage1} alt="logo" style={{ height: 40, display: open ? '' : 'none' }} />
                  </Stack>
                </Box>
                <Stack direction="row" spacing={2}>
                  <IconButton color="secondary" onClick={() => drawerToggler(true)}>
                    <MenuOutlined style={{ color: 'inherit' }} />
                  </IconButton>
                </Stack>
                <Drawer
                  anchor="top"
                  open={drawerToggle}
                  onClose={drawerToggler(false)}
                  sx={{ '& .MuiDrawer-paper': { backgroundImage: 'none' } }}
                >
                  <Box
                    sx={{
                      width: 'auto',
                      '& .MuiListItemIcon-root': {
                        fontSize: '14px',
                        minWidth: 28
                      }
                    }}
                    role="presentation"
                    onClick={drawerToggler(false)}
                    onKeyDown={drawerToggler(false)}
                  >
                    <List>
                      <Link style={{ textDecoration: 'none' }} href="/login">
                        <ListItemButton component="span">
                          <ListItemText
                            primary={!isLoggedIn ? 'Login' : 'Home'}
                            primaryTypographyProps={{ variant: 'p2m', color: 'text.primary' }}
                          />
                        </ListItemButton>
                      </Link>
                      {isLoggedIn && (
                        <>
                          <Link style={{ textDecoration: 'none' }} onClick={logout}>
                            <ListItemButton component="span">
                              <ListItemText primary="Logout" primaryTypographyProps={{ variant: 'p2m', color: 'text.primary' }} />
                            </ListItemButton>
                          </Link>
                        </>
                      )}
                    </List>
                  </Box>
                </Drawer>
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
      </ElevationScroll>
      <Popper
        placement="bottom-start"
        open={logoOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 9]
              }
            }
          ]
        }}
        sx={{ zIndex: 2 }}
      >
        {({ TransitionProps }) => (
          <Transitions type="fade" in={logoOpen} {...TransitionProps}>
            {logoOpen && (
              <Paper
                sx={{
                  border: `solid 1px ${theme.palette.grey[300]}`,
                  boxShadow: theme.customShadows.z1
                }}
              >
                <ClickAwayListener onClickAway={close}>
                  <MainCard elevation={0} border={false} sx={{ borderRadius: '4px' }}>
                    <Stack spacing={2} alignItems="flex-start">
                      <Typography color="secondary">Switch to</Typography>
                      <Box onClick={() => window.open(`${process.env['REACT_APP_SPROUT_URL']}`, '_blank')} sx={{ cursor: 'pointer' }}>
                        <img src={KhoraImage} alt="home logo" style={{ height: '20px', width: 'auto', cursor: 'pointer' }} />
                      </Box>
                      <Box onClick={() => window.open(`${process.env['REACT_APP_MARKETPLACE_URL']}`, '_blank')} sx={{ cursor: 'pointer' }}>
                        <img src={MarketImage} alt="market logo" style={{ height: '20px', width: 'auto', cursor: 'pointer' }} />
                      </Box>
                      <Box
                        onClick={() => window.open(`${process.env['REACT_APP_SPROUT_URL']}/dashboard`, '_blank')}
                        sx={{ cursor: 'pointer' }}
                      >
                        <img src={SproutImage} alt="sprout logo" style={{ height: '34px', width: 'auto', cursor: 'pointer' }} />
                      </Box>
                    </Stack>
                  </MainCard>
                </ClickAwayListener>
              </Paper>
            )}
          </Transitions>
        )}
      </Popper>
      <Popper
        placement="bottom-start"
        open={_logoOpen}
        anchorEl={_anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 9]
              }
            }
          ]
        }}
        sx={{ zIndex: 2 }}
      >
        {({ TransitionProps }) => (
          <Transitions type="fade" in={_logoOpen} {...TransitionProps}>
            {_logoOpen && (
              <Paper
                sx={{
                  border: `solid 1px ${theme.palette.grey[300]}`,
                  boxShadow: theme.customShadows.z1
                }}
              >
                <ClickAwayListener onClickAway={_close}>
                  <MainCard elevation={0} border={false} sx={{ borderRadius: '4px' }}>
                    <Stack spacing={2} alignItems="flex-start">
                      <Typography color="secondary">Switch to</Typography>
                      <Box onClick={() => window.open(`${process.env['REACT_APP_SPROUT_URL']}`, '_blank')} sx={{ cursor: 'pointer' }}>
                        <img src={KhoraImage} alt="home logo" style={{ height: '20px', width: 'auto', cursor: 'pointer' }} />
                      </Box>
                      <Box onClick={() => window.open(`${process.env['REACT_APP_MARKETPLACE_URL']}`, '_blank')} sx={{ cursor: 'pointer' }}>
                        <img src={MarketImage} alt="market logo" style={{ height: '20px', width: 'auto', cursor: 'pointer' }} />
                      </Box>
                      <Box
                        onClick={() => window.open(`${process.env['REACT_APP_SPROUT_URL']}/dashboard`, '_blank')}
                        sx={{ cursor: 'pointer' }}
                      >
                        <img src={SproutImage} alt="sprout logo" style={{ height: '34px', width: 'auto', cursor: 'pointer' }} />
                      </Box>
                    </Stack>
                  </MainCard>
                </ClickAwayListener>
              </Paper>
            )}
          </Transitions>
        )}
      </Popper>
    </>
  );
};

Header.propTypes = {
  layout: PropTypes.string
};

export default Header;
