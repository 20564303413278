// third-party
import { FormattedMessage } from 'react-intl';

// assets
// import { LineChartOutlined, IdcardOutlined, DatabaseOutlined } from '@ant-design/icons';
// import ContactUsIcon from '@mui/icons-material/AddIcCallOutlined';
import YardOutlinedIcon from '@mui/icons-material/YardOutlined';
// icons
const icons = { YardOutlinedIcon };

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const membership = {
  id: 'membership',
  title: <FormattedMessage id="Membership" />,
  type: 'item',
  url: '/membership',
  icon: { image: false, icon: icons.YardOutlinedIcon }
};
const membership_ = {
  id: 'membership',
  title: <FormattedMessage id="Membership" />,
  type: 'collapse',
  icon: { image: false, icon: icons.YardOutlinedIcon },
  children: [
    {
      id: 'membership_',
      title: <FormattedMessage id="Membership" />,
      type: 'item',
      url: '/membership'
    }
  ]
};

export { membership, membership_ };
