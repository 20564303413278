// third-party
import { FormattedMessage } from 'react-intl';

// assets
import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined';
// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const stripe = {
  id: 'stripe',
  title: <FormattedMessage id="Stripe" />,
  type: 'collapse',
  icon: { image: false, icon: PaymentOutlinedIcon },
  children: [
    {
      id: 'stripeproduct',
      title: <FormattedMessage id="Products" />,
      type: 'item',
      url: '/stripe/product',
      children: [
        {
          id: 'stripeproduct_create',
          title: <FormattedMessage id="Add Product" />,
          type: 'hidden',
          url: '/stripe/product/create'
        },
        {
          id: 'stripeproduct_update',
          title: <FormattedMessage id="Edit Product" />,
          type: 'hidden',
          url: '/stripe/product/update'
        }
      ]
    },
    {
      id: 'stripetaxrate',
      title: <FormattedMessage id="Tax Rates" />,
      type: 'item',
      url: '/stripe/tax_rate',
      children: [
        {
          id: 'stripetaxrate_create',
          title: <FormattedMessage id="Add Tax Rate" />,
          type: 'hidden',
          url: '/stripe/tax_rate/create'
        },
        {
          id: 'stripetaxrate_update',
          title: <FormattedMessage id="Edit Tax Rate" />,
          type: 'hidden',
          url: '/stripe/tax_rate/update'
        }
      ]
    }
  ]
};

export default stripe;
