export const drawerWidth = 300;

// ==============================|| THEME CONFIG  ||============================== //

const config = {
  defaultPath: '/users',
  fontFamily: `'Poppins', sans-serif`,
  i18n: 'en',
  miniDrawer: false,
  container: false,
  mode: 'light',
  themeDirection: 'ltr'
};

export default config;
