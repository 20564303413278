import { FormattedMessage } from 'react-intl';
import growingplan_full from './growingplan_full';
import resource from './resource';
import stripe from './stripe';
import { growingplan, growingplan_ } from './growingplan';
import dropdown from './dropdown';
import { vendor, vendor_ } from './vendor';
import { user, user_ } from './user';
// import { observation, observation_ } from './observation';
import { announcement, announcement_ } from './announcement';
// import { publish_list, publish_list_ } from './publish_list';
import { membership, membership_ } from './membership';

//// Marketplace menu items ////
import { market_user, market_user_ } from './market/user';
import market_product from './market/product';
import taxes from './market/taxes';
import market_vendor from './market/vendor';
import { market_review, market_review_ } from './market/review';
import market_news from './market/news';
import { market_investor, market_investor_ } from './market/investor';
import { market_farmers_market, market_farmers_market_ } from './market/farmers_market';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [
    {
      id: 'group-sprout',
      title: <FormattedMessage id="Sprout" />,
      bgColor: '#effaef',
      type: 'group',
      children: [user, growingplan_full, growingplan, resource, vendor, dropdown, announcement, membership, stripe]
    },
    {
      id: 'group-marketplace',
      title: <FormattedMessage id="Marketplace" />,
      bgColor: '#faefef',
      type: 'group',
      children: [market_user, market_product, taxes, market_vendor, market_review, market_news, market_investor, market_farmers_market]
    }
  ],
  items_: [
    {
      id: 'group-sprout',
      title: <FormattedMessage id="Sprout" />,
      bgColor: '#effaef',
      type: 'group',
      children: [user_, growingplan_full, growingplan_, resource, vendor_, dropdown, announcement_, membership_, stripe]
    },
    {
      id: 'group-marketplace',
      title: <FormattedMessage id="Marketplace" />,
      bgColor: '#faefef',
      type: 'group',
      children: [market_user_, market_product, taxes, market_vendor, market_review_, market_news, market_investor_, market_farmers_market_]
    }
  ]
};

export default menuItems;
