// third-party
import { FormattedMessage } from 'react-intl';

// assets
import UserIcon from '@mui/icons-material/PersonOutlineOutlined';

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const market_user = {
  id: 'market_user',
  title: <FormattedMessage id="User Management" />,
  type: 'item',
  url: '/market/users',
  icon: { image: false, icon: UserIcon },
  children: [
    {
      id: 'market_user_detail',
      title: <FormattedMessage id="User Detail Information" />,
      type: 'hidden',
      url: '/market/users/detail'
    }
  ]
};
const market_user_ = {
  id: 'market_user',
  title: <FormattedMessage id="User Management" />,
  type: 'collapse',
  icon: { image: false, icon: UserIcon },
  children: [
    {
      id: 'market_user_',
      title: <FormattedMessage id="User Management" />,
      type: 'item',
      url: '/market/users',
      children: [
        {
          id: 'market_user_detail',
          title: <FormattedMessage id="User Detail Information" />,
          type: 'hidden',
          url: '/market/users/detail'
        }
      ]
    }
  ]
};

export { market_user, market_user_ };
