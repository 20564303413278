// third-party
import { FormattedMessage } from 'react-intl';

// assets
import RateReviewOutlinedIcon from '@mui/icons-material/RateReviewOutlined';
// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const market_review = {
  id: 'market_review',
  title: <FormattedMessage id="Review Management" />,
  type: 'item',
  url: '/market/review',
  icon: { image: false, icon: RateReviewOutlinedIcon }
};

const market_review_ = {
  id: 'market_review',
  title: <FormattedMessage id="Review Management" />,
  type: 'collapse',
  icon: { image: false, icon: RateReviewOutlinedIcon },
  children: [
    {
      id: 'market_review_',
      title: <FormattedMessage id="Review Management" />,
      type: 'item',
      url: '/market/review'
    }
  ]
};

export { market_review, market_review_ };
