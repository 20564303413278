// ==============================|| OVERRIDES - ALERT TITLE ||============================== //

export default function Accordion() {
  return {
    MuiAccordion: {
      defaultProps: {
        disableGutters: true,
        square: true,
        elevation: 0
      },
      styleOverrides: {
        root: {
          marginBottom: '30px',
          '&:not(:last-child)': {
            borderBottom: 0
          },
          '&:before': {
            display: 'none'
          }
        }
      }
    }
  };
}
