import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Alert, Button, Fade, Grow, Slide, IconButton, AlertTitle } from '@mui/material';
import MuiSnackbar from '@mui/material/Snackbar';

// project import
import { closeSnackbar } from 'store/reducers/snackbar';

// assets
import { CloseOutlined } from '@mui/icons-material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';

// animation function
function TransitionSlideLeft(props) {
  return <Slide {...props} direction="left" />;
}

function TransitionSlideUp(props) {
  return <Slide {...props} direction="up" />;
}

function TransitionSlideRight(props) {
  return <Slide {...props} direction="right" />;
}

function TransitionSlideDown(props) {
  return <Slide {...props} direction="down" />;
}

function GrowTransition(props) {
  return <Grow {...props} />;
}

// animation options
const animation = {
  SlideLeft: TransitionSlideLeft,
  SlideUp: TransitionSlideUp,
  SlideRight: TransitionSlideRight,
  SlideDown: TransitionSlideDown,
  Grow: GrowTransition,
  Fade
};

// ==============================|| SNACKBAR ||============================== //

const Snackbar = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const snackbar = useSelector((state) => state.snackbar);
  const { actionButton, anchorOrigin, alert, message, open, transition, variant } = snackbar;

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(closeSnackbar());
  };

  return (
    <>
      {/* default snackbar */}
      {variant === 'default' && (
        <MuiSnackbar
          anchorOrigin={anchorOrigin}
          open={open}
          autoHideDuration={4000}
          onClose={handleClose}
          message={message}
          TransitionComponent={animation[transition]}
          action={
            <>
              <Button color="secondary" size="small" onClick={handleClose}>
                UNDO
              </Button>
              <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose} sx={{ mt: 0.25 }}>
                <CloseOutlined />
              </IconButton>
            </>
          }
        />
      )}

      {/* alert snackbar */}
      {variant === 'alert' && (
        <MuiSnackbar
          TransitionComponent={animation[transition]}
          anchorOrigin={anchorOrigin}
          open={open}
          autoHideDuration={4000}
          onClose={handleClose}
        >
          <Alert
            variant={alert.variant}
            color={alert.color}
            severity={alert.color}
            iconMapping={{
              success: (
                <CheckCircleOutlineIcon
                  style={{
                    color: theme.palette.grey[25],
                    fontSize: '1.25rem'
                  }}
                />
              ),
              error: (
                <CancelIcon
                  style={{
                    color: theme.palette.grey[25],
                    fontSize: '1.25rem'
                  }}
                />
              )
            }}
            action={
              <>
                {actionButton !== false && (
                  <Button color={alert.color} size="small" onClick={handleClose}>
                    UNDO
                  </Button>
                )}
                <IconButton aria-label="close" variant="contained" onClick={handleClose} sx={{ p: 0 }}>
                  <CloseOutlined
                    style={{
                      color: theme.palette.grey[50],
                      fontSize: '1.25rem'
                    }}
                  />
                </IconButton>
              </>
            }
            sx={{
              ...(alert.variant === 'outlined' && {
                bgcolor: 'grey.0'
              })
            }}
          >
            <AlertTitle sx={{ fontSize: '14px' }}>
              <b>{alert.color.charAt(0).toUpperCase() + alert.color.slice(1)}</b>
            </AlertTitle>
            {message}
          </Alert>
        </MuiSnackbar>
      )}
    </>
  );
};

export default Snackbar;
