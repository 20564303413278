// third-party
import { FormattedMessage } from 'react-intl';

// assets
// import { LineChartOutlined, IdcardOutlined, DatabaseOutlined } from '@ant-design/icons';
import ListManagementIcon from 'assets/icons/list_management/KH-1247_List_Management.png';

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const resource = {
  id: 'resource',
  title: <FormattedMessage id="List Management" />,
  type: 'collapse',
  icon: { image: true, icon: ListManagementIcon },
  children: [
    {
      id: 'resourceseed',
      title: <FormattedMessage id="Seeds" />,
      type: 'item',
      url: '/list/seed',
      children: [
        {
          id: 'resourceseed_create',
          title: <FormattedMessage id="Add Seed" />,
          type: 'hidden',
          url: '/list/seed/create'
        },
        {
          id: 'resourceseed_update',
          title: <FormattedMessage id="Edit Seed" />,
          type: 'hidden',
          url: '/list/seed/update'
        }
      ]
    },
    {
      id: 'resourcemicrogreen',
      title: <FormattedMessage id="Microgreens" />,
      type: 'item',
      url: '/list/microgreen',
      children: [
        {
          id: 'resourcemicrogreen_create',
          title: <FormattedMessage id="Add Microgreen" />,
          type: 'hidden',
          url: '/list/microgreen/create'
        },
        {
          id: 'resourcemicrogreen_update',
          title: <FormattedMessage id="Edit Microgreen" />,
          type: 'hidden',
          url: '/list/microgreen/update'
        }
      ]
    },
    {
      id: 'resourcemushroom',
      title: <FormattedMessage id="Mushrooms" />,
      type: 'item',
      url: '/list/mushroom',
      children: [
        {
          id: 'resourcemushroom_create',
          title: <FormattedMessage id="Add Mushroom" />,
          type: 'hidden',
          url: '/list/mushroom/create'
        },
        {
          id: 'resourcemushroom_update',
          title: <FormattedMessage id="Edit Mushroom" />,
          type: 'hidden',
          url: '/list/mushroom/update'
        }
      ]
    },
    {
      id: 'resourcecutting',
      title: <FormattedMessage id="Cuttings" />,
      type: 'item',
      url: '/list/cutting',
      children: [
        {
          id: 'resourcecutting_create',
          title: <FormattedMessage id="Add Cutting" />,
          type: 'hidden',
          url: '/list/cutting/create'
        },
        {
          id: 'resourceseed_update',
          title: <FormattedMessage id="Edit Cutting" />,
          type: 'hidden',
          url: '/list/cutting/update'
        }
      ]
    },
    {
      id: 'resourcelight',
      title: <FormattedMessage id="Lighting Plans" />,
      type: 'item',
      url: '/list/light',
      children: [
        {
          id: 'resourcelight_create',
          title: <FormattedMessage id="Add Lighting Plan" />,
          type: 'hidden',
          url: '/list/light/create'
        },
        {
          id: 'resourcelight_update',
          title: <FormattedMessage id="Edit Lighting Plan" />,
          type: 'hidden',
          url: '/list/light/update'
        },
        {
          id: 'resourcelight_create_new',
          title: <FormattedMessage id="Add Lighting Plan (New)" />,
          type: 'hidden',
          url: '/list/light/create_new'
        },
        {
          id: 'resourcelight_update_new',
          title: <FormattedMessage id="Edit Lighting Plan (New)" />,
          type: 'hidden',
          url: '/list/light/update_new'
        }
      ]
    },
    {
      id: 'resourceirrigation',
      title: <FormattedMessage id="Irrigation Plans" />,
      type: 'item',
      url: '/list/irrigation',
      children: [
        {
          id: 'resourceirrigation_create',
          title: <FormattedMessage id="Add Irrigation Plan" />,
          type: 'hidden',
          url: '/list/irrigation/create'
        },
        {
          id: 'resourceirrigation_update',
          title: <FormattedMessage id="Edit Irrigation Plan" />,
          type: 'hidden',
          url: '/list/irrigation/update'
        }
      ]
    },
    {
      id: 'resourceenvironment',
      title: <FormattedMessage id="Environment Plans" />,
      type: 'item',
      url: '/list/environment',
      children: [
        {
          id: 'resourceenvironment_create',
          title: <FormattedMessage id="Add Environment Plan" />,
          type: 'hidden',
          url: '/list/environment/create'
        },
        {
          id: 'resourceenvironment_update',
          title: <FormattedMessage id="Edit Environment Plan" />,
          type: 'hidden',
          url: '/list/environment/update'
        }
      ]
    }
  ]
};

export default resource;
