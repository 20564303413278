import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

// material-ui
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import { useTheme } from '@mui/material/styles';
import { Divider, Grid, Typography } from '@mui/material';

// project imports
import MainCard from '../MainCard';
import useAuth from 'hooks/useAuth';

// assets
import { ApartmentOutlined, HomeFilled, HomeOutlined } from '@ant-design/icons';

// ==============================|| BREADCRUMBS ||============================== //

const Breadcrumbs = ({
  card,
  divider = true,
  icon,
  icons,
  maxItems,
  navigation,
  rightAlign,
  separator,
  title,
  titleBottom,
  sx,
  ...others
}) => {
  const theme = useTheme();
  const location = useLocation();
  const [main, setMain] = useState();
  const [item, setItem] = useState();
  const [parentItem, setParentItem] = useState();
  const { growSetting } = useAuth();
  const iconSX = {
    marginRight: theme.spacing(0.75),
    marginTop: `-${theme.spacing(0.25)}`,
    width: '1rem',
    height: '1rem',
    color: theme.palette.secondary.main
  };

  // set active item state
  const getCollapse = (menu, parent = []) => {
    if (menu.children) {
      menu.children.filter((collapse) => {
        if (collapse.type && collapse?.children) {
          getCollapse(collapse, [...parent, menu]);
        }
        if (collapse.type && (collapse.type === 'item' || collapse.type === 'hidden')) {
          if (location.pathname === collapse.url || new RegExp(collapse.url + '/\\d').test(location.pathname)) {
            setMain([...parent, menu]);
            setItem(collapse);
            setParentItem(menu);
          }
        }
        return false;
      });
    }
  };

  useEffect(() => {
    if (!growSetting?.GrowAuto) {
      navigation?.items?.map((menu) => {
        if (menu.type && menu.type === 'group') {
          getCollapse(menu);
        }
        return false;
      });
    }
    if (parseInt(growSetting?.GrowAuto) === 1) {
      navigation?.items?.map((menu) => {
        if (menu.type && menu.type === 'group') {
          getCollapse(menu);
        }
        return false;
      });
    }
    if (parseInt(growSetting?.GrowAuto) === 2) {
      navigation?.items2?.map((menu) => {
        if (menu.type && menu.type === 'group') {
          getCollapse(menu);
        }
        return false;
      });
    }
    if (parseInt(growSetting?.GrowAuto) === 3) {
      navigation?.items3?.map((menu) => {
        if (menu.type && menu.type === 'group') {
          getCollapse(menu);
        }
        return false;
      });
    } // eslint-disable-next-line
  }, [growSetting, location.pathname]);

  // only used for component demo breadcrumbs
  // if (location.pathname === '/components-overview/breadcrumbs') {
  //   location.pathname = '/dashboard/analytics';
  // }

  // item separator
  const SeparatorIcon = separator;
  const separatorIcon = separator ? <SeparatorIcon style={{ fontSize: '0.75rem', marginTop: 2 }} /> : '/';

  let mainContent;
  let itemContent;
  let breadcrumbContent = <Typography />;
  let itemTitle = '';
  let CollapseIcon;
  let ItemIcon;

  // collapse item
  if (main) {
    let toPath = document.location.pathname;
    if (item && item.type === 'hidden') {
      if (parentItem?.url) {
        toPath = parentItem?.url;
      } else {
        let pos = toPath.lastIndexOf('/');
        const p = toPath.search(new RegExp('/\\d'));
        if (p != -1) {
          pos = toPath.lastIndexOf('/', p - 1);
        }
        if (pos > 0) toPath = toPath.substring(0, pos);
      }
    }
    mainContent = main.map((menu, idx) => {
      if (menu?.children && menu.type !== 'group') {
        CollapseIcon = menu.icon ? menu.icon : ApartmentOutlined;
        return (
          <Typography key={'menu_' + idx} component={Link} to={toPath} variant="h6" sx={{ textDecoration: 'none' }} color="textSecondary">
            {icons && <CollapseIcon style={iconSX} />}
            {menu.title}
          </Typography>
        );
      }
    });
  }

  // items
  if (item && (item.type === 'item' || item.type === 'hidden')) {
    itemTitle = item.title;

    ItemIcon = item.icon ? item.icon : ApartmentOutlined;
    itemContent = (
      <Typography variant="subtitle1" color="textPrimary">
        {icons && <ItemIcon style={iconSX} />}
        {itemTitle}
      </Typography>
    );

    // main
    if (item.breadcrumbs !== false) {
      breadcrumbContent = (
        <MainCard
          border={card}
          sx={card === false ? { mb: 3, p: 0, bgcolor: 'transparent', ...sx } : { mb: 3, p: 0, ...sx }}
          {...others}
          content={card}
          shadow="none"
        >
          <Grid
            container
            direction={rightAlign ? 'row' : 'column'}
            justifyContent={rightAlign ? 'space-between' : 'flex-start'}
            alignItems={rightAlign ? 'center' : 'flex-start'}
            spacing={1}
          >
            {title && !titleBottom && (
              <Grid item>
                <Typography variant="h2">{item.title}</Typography>
              </Grid>
            )}
            <Grid item>
              <MuiBreadcrumbs aria-label="breadcrumb" maxItems={maxItems || 8} separator={separatorIcon}>
                <Typography component={Link} to="/" color="textSecondary" variant="h6" sx={{ textDecoration: 'none' }}>
                  {icons && <HomeOutlined style={iconSX} />}
                  {icon && !icons && <HomeFilled style={{ ...iconSX, marginRight: 0 }} />}
                  {(!icon || icons) && 'Home'}
                </Typography>
                {mainContent}
                {itemContent}
              </MuiBreadcrumbs>
            </Grid>
          </Grid>
          {card === false && divider !== false && <Divider sx={{ mt: 2 }} />}
        </MainCard>
      );
    }
  }

  return breadcrumbContent;
};

Breadcrumbs.propTypes = {
  card: PropTypes.bool,
  divider: PropTypes.bool,
  icon: PropTypes.bool,
  icons: PropTypes.bool,
  maxItems: PropTypes.number,
  navigation: PropTypes.object,
  rightAlign: PropTypes.bool,
  separator: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  title: PropTypes.bool,
  titleBottom: PropTypes.bool,
  sx: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};

export default Breadcrumbs;
